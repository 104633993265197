import React from "react"
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Shell from '../components/global/shell'
import Container from '../components/global/container'


const Scales = () => {

  return (
    <Shell>
      <div className='md:h-screen flex flex-rows flex-wrap md:flex-nowrap bg-gray-200'>
        
        <div className='mx-auto w-10/12 md:w-9/12 lg:w-8-12 order-1 md:order-2 mb-20 md:mb-0 mt-5 p-8 h-100 bg-gray-50 max-w-7xl'>
          {/* {mainContainer} */}
        </div>

        <div className='w-screen md:w-40 h-full order-2 md:order-1'>
          {/* <SearchBox data={yaml} dataGrouped={subscales} setVariable={setVariable} 
                              setActiveScale={setActiveScale}
                              variable={variable} aesthetic={aesthetic}
                              setAesthetic={setAesthetic}/> */}
        </div>

      </div>

    </Shell>
  )
}

export default Scales



// export const query = graphql`
//   query {
//     scales: allMdx(filter: {fileAbsolutePath: {regex: "/viz-pages/"}},
//            sort: {fields: frontmatter___date, order: DESC}) {
//       edges {
//         node {
//           frontmatter {
//             title
//             description
//             date(formatString: "MMMM Do, YYYY")
//             thumbnail {
//               childImageSharp {
//                 fluid(maxWidth: 525) {
//                   ...GatsbyImageSharpFluid_noBase64
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `